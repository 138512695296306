import React from "react";
import {
  IonRadioGroup,
  IonGrid,
  IonRow,
  IonCol,
  IonLabel,
  IonItem,
  IonRadio,
} from "@ionic/react";

import "./MBYesOrNoRadioGroup.scss";
import { isMobile } from "../../functions/common";
import { MBCOLORS } from "../../constants/config";

interface MBYesOrNoRadioGroupProps {
  label?: string;
  value: boolean | null;
  onChange: (result: boolean) => void;
  optionYesLabel?: string;
  optionNoLabel?: string;
  disabled?: boolean;
}
export const MBYesOrNoRadioGroup = (props: MBYesOrNoRadioGroupProps) => {
  const {
    label,
    value,
    onChange,
    optionYesLabel = "Yes",
    optionNoLabel = "No",
    disabled,
  } = props;
  return (
    <IonRadioGroup
      className="mb-yesno-radio-group-container"
      value={value}
      onIonChange={(e) => onChange(e.detail.value as boolean)}
    >
      <IonGrid className="ion-no-padding">
        <IonRow>
          {!!label && (
            <IonCol
              size="4"
              className="mb-yesno-radio-title-col ion-no-padding"
            >
              <IonLabel className="mb-yesno-title-label ion-no-margin mb-paragraph bold">
                {label}
              </IonLabel>
            </IonCol>
          )}
          <IonCol size={!!label ? "4" : "6"} className="ion-no-padding">
            <IonItem color={MBCOLORS.tertiary} lines="none">
              <IonLabel
                className={`ion-no-margin ${
                  isMobile() ? "mb-paragraph" : "mb-body"
                }`}
              >
                {optionYesLabel}
              </IonLabel>
              <IonRadio
                mode="md"
                disabled={disabled}
                className="mb-yesno-radio ion-no-margin"
                slot="start"
                value={true}
              />
            </IonItem>
          </IonCol>
          <IonCol size={!!label ? "4" : "6"} className="ion-no-padding">
            <IonItem color={MBCOLORS.tertiary} lines="none">
              <IonLabel
                className={`ion-no-margin ${
                  isMobile() ? "mb-paragraph" : "mb-body"
                }`}
              >
                {optionNoLabel}
              </IonLabel>
              <IonRadio
                mode="md"
                disabled={disabled}
                className="mb-yesno-radio ion-no-margin"
                slot="start"
                value={false}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonRadioGroup>
  );
};
