import * as _ from "lodash";
import * as capacitorStorage from "../functions/localStorageCapacitor";
import { getEnv } from "../env";
import { auth, firestore, fb } from "../firebase";
import { UserType } from "../interface";
import {
  AccountType,
  Patient,
  Doctor,
  Hospital,
  TimeKeeping,
  PreRegistrationUser,
  SubscribedEmails,
  Coordinates,
} from "../models";
import {
  ACCOUNT_TYPES,
  MAIL,
  PRE_REGISTRATION_SIGN_UPS,
  SUBSCRIBED_EMAILS,
} from "../constants/dbCollections";
import { USER_TYPES, USER_TYPE_KEYS } from "../constants/config";

var env = getEnv();
let senderEmailInfo = env.notification.senderEmailInfo;
let senderEmailSupport = env.notification.senderEmailSupport;

export const signInWithFacebook = async () => {
  // Log in will trigger the onAuthStateChangeUser in Router.tsx
  let provider = new fb.auth.FacebookAuthProvider();
  auth.useDeviceLanguage();
  provider.setCustomParameters({
    display: "popup",
  });
  const result = await auth.signInWithPopup(provider);

  if (!_.isNull(result.user)) {
    const docId = result.user.uid;
    const user = await firestore.collection(ACCOUNT_TYPES).doc(docId).get();

    if (!user.exists) {
      await firestore.collection(ACCOUNT_TYPES).doc(docId).set({
        userId: docId,
        createdAt: new Date(),
      });
    }
  }
};

export const signInWithGoogle = async () => {
  // Log in will trigger the onAuthStateChangeUser in Router.tsx
  let provider = new fb.auth.GoogleAuthProvider();
  auth.useDeviceLanguage();
  provider.setCustomParameters({
    login_hint: "user@example.com",
  });
  const result = await auth.signInWithPopup(provider);

  if (!_.isNull(result.user)) {
    const docId = result.user.uid;
    const user = await firestore.collection(ACCOUNT_TYPES).doc(docId).get();

    if (!user.exists) {
      await firestore.collection(ACCOUNT_TYPES).doc(docId).set({
        userId: docId,
        createdAt: new Date(),
      });
    }
  }
};

export const createUserUsingEmail = async (
  emailAddress: string,
  password: string
) => {
  const result: firebase.auth.UserCredential = await auth.createUserWithEmailAndPassword(
    emailAddress,
    password
  );

  if (!_.isNull(result.user)) {
    const docId = result.user.uid;
    const user = await firestore.collection(ACCOUNT_TYPES).doc(docId).get();

    if (!user.exists) {
      await firestore.collection(ACCOUNT_TYPES).doc(docId).set({
        userId: docId,
        createdAt: new Date(),
      });
      return docId;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const signIn = (emailAddress: string, password: string) =>
  auth.signInWithEmailAndPassword(emailAddress, password);

export const getUserAccountType = async (
  uid: string,
  callback: (accoutType: AccountType) => void
) => {
  firestore
    .collection(ACCOUNT_TYPES)
    .doc(uid)
    .onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          callback({ userId: snapshot.id, ...snapshot.data() } as AccountType);
        } else {
          callback({} as AccountType);
        }
      },
      (error) => {
        callback({} as AccountType);
      }
    );
};

export const getUserDetailsRealTime = (
  docId: string,
  userType: UserType,
  callback: (user: Patient | Hospital | Doctor) => void
) => {
  if (
    userType !== USER_TYPES.support.id &&
    userType !== USER_TYPES.techAdmin.id
  ) {
    const unsubscribe = firestore
      .collection(USER_TYPES[USER_TYPE_KEYS[userType]].collection)
      .doc(docId)
      .onSnapshot(
        (snapshot) => {
          if (snapshot.exists) {
            const result = snapshot.data();
            callback({
              docId: snapshot.id,
              ...result,
            } as Patient | Hospital | Doctor);
          } else {
            callback({} as Patient | Hospital | Doctor);
          }
        },
        (error) => {
          callback({} as Patient | Hospital | Doctor);
        }
      );
    return unsubscribe;
  } else {
    callback({} as Patient | Hospital | Doctor);
    return () => {};
  }
};

export const getUserDetails = async (docId: string, userType: UserType) => {
  try {
    const result = await firestore
      .collection(USER_TYPES[USER_TYPE_KEYS[userType]].collection)
      .doc(docId)
      .get();

    console.log("result -- ", result);
    if (result.exists) {
      console.log("result -- ", result.data());
      return { ...result.data(), docId: result.id } as
        | Patient
        | Hospital
        | Doctor
        | TimeKeeping;
    } else {
      console.log("dont exists!!!");
      return {} as Patient | Hospital | Doctor | TimeKeeping;
    }
  } catch (error) {
    console.log(error);
    return {} as Patient | Hospital | Doctor | TimeKeeping;
  }
};

export const signOut = async () => {
  await capacitorStorage.clear();
  auth.signOut();
};

export const updatePassword = async (
  oldPassword: string,
  newPassword: string
) => {
  let user = auth.currentUser;
  if (user && user.email) {
    await user.reauthenticateWithCredential(
      fb.auth.EmailAuthProvider.credential(user.email, oldPassword)
    );
    await user.updatePassword(newPassword);
  }
};

export const doSendResetPasswordEmail = async (email: string) => {
  await auth.sendPasswordResetEmail(email);
};

export const doResetPassword = async (code: string, password: string) => {
  await auth.confirmPasswordReset(code, password);
};

export const doSendVerificationEmail = async () => {
  let user = auth.currentUser;

  try {
    if (user) {
      await user.sendEmailVerification();
    }
  } catch (error) {
    console.log("error - doSendVerificationEmail -- ", error);
  }
};

export const doApplyActionCode = async (actionCode: string) => {
  await auth.applyActionCode(actionCode);
};

export const sendEmail = async (
  emailAddress: string,
  subject: string,
  message: string
) => {
  await firestore.collection(MAIL).add({
    from: senderEmailInfo,
    to: emailAddress,
    message: {
      subject: subject,
      html: message,
    },
  });
};

export const sendEmailToSupport = async (subject: string, message: string) => {
  await firestore.collection(MAIL).add({
    from: senderEmailInfo,
    to: senderEmailSupport,
    message: {
      subject: subject,
      html: message,
    },
  });
};

export const addPreRegistrationSignUps = async (
  firstName: string,
  lastName: string,
  emailAddress: string,
  phoneNumber: string,
  location: Coordinates,
  isSubscribeToNewsletter: boolean
  // province: string,
  // cityMunicipality: string,
  // hospital1?: string,
  // hospital2?: string,
  // hospital3?: string,
  // service1?: string,
  // service2?: string,
  // service3?: string
) => {
  await firestore.collection(PRE_REGISTRATION_SIGN_UPS).add({
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    location,
    isSubscribeToNewsletter,
    // province,
    // cityMunicipality,
    // hospital1,
    // hospital2,
    // hospital3,
    // service1,
    // service2,
    // service3,
    createdAt: new Date(),
  });
};

export const addSubscribedEmails = async (emailAddress: string) => {
  await firestore.collection(SUBSCRIBED_EMAILS).add({
    emailAddress,
    createdAt: new Date(),
  });
};

export const getPreregistrations = (
  callback: (preRegistrations: PreRegistrationUser[], error?: string) => void
) => {
  try {
    firestore.collection(PRE_REGISTRATION_SIGN_UPS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(
          snapshot.docs.map((preRegistration) => {
            return preRegistration.data() as PreRegistrationUser;
          })
        );
      } else {
        callback([]);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING getPreregistrations -- ", e);
    callback([], e);
  }
};

export const getEmailSubscriptions = (
  callback: (preRegistrations: SubscribedEmails[], error?: string) => void
) => {
  try {
    firestore.collection(SUBSCRIBED_EMAILS).onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        callback(
          snapshot.docs.map((subscribedEmail) => {
            return subscribedEmail.data() as SubscribedEmails;
          })
        );
      } else {
        callback([]);
      }
    });
  } catch (e) {
    console.log("ERROR GETTING getEmailSubscriptions -- ", e);
    callback([], e);
  }
};
