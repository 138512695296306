import * as _ from "lodash";
import { Helmet } from "react-helmet";
import React from "react";

import "./HospitalHome.scss";
import * as services from "../../services";
import * as routes from "../../constants/routes";
import { MBContainer } from "../../components/MBContainer/MBContainer";
import { MBProps, AppointmentView } from "../../interface";
import {
  Doctor,
  Hospital,
  HospitalDoctorAppointmentsView,
  HospitalService,
  HospitalServiceAppointmentsView,
} from "../../models";
import {
  IonLoading,
  IonIcon,
  IonLabel,
  IonButton,
  IonModal,
} from "@ionic/react";
import { MSGS_COMMON } from "../../constants/messages";
import { MBDashboardCard } from "../../components/MBDashboardCard/MBDashboardCard";
import { MBCOLORS, USER_TYPES } from "../../constants/config";
import { isMobile, isSameDate } from "../../functions/common";
import { DoctorHospitalAccountSetup } from "../../components/DoctorHospitalAccountSetup/DoctorHospitalAccountSetup";
import { ServiceAccountSetup } from "../../components/ServiceAccountSetup/ServiceAccountSetup";
import { TimeKeepingForm } from "../../components/TimeKeepingForm/TimeKeepingForm";

class HospitalHome extends React.Component<MBProps> {
  state = {
    viewMode: 2 as AppointmentView,
    loading: true,
    isOpenAddDoctorServices: false,
    isOpenDoctor: false,
    isOpenService: false,
    isOpenTimeKeeping: false,
    selected: 0,
    doctors: [] as Doctor[],
    services: [] as HospitalService[],
    doctorAppointments: [] as HospitalDoctorAppointmentsView[],
    serviceAppointments: [] as HospitalServiceAppointmentsView[],
  };
  //todo: get doctors and services to check
  // todo: get all appointments to display
  componentDidMount = () => {
    this.getAllServices();
    this.getAllDoctors();
    this.getHospitalDoctorAppointments();
    this.getHospitalServiceAppointments();
  };

  getAllDoctors = () => {
    services.getAllDoctors((doctors) => {
      this.setState({
        doctors,
      });
    });
  };
  getAllServices = () => {
    services.getAllServices((services) => {
      this.setState({
        services,
      });
    });
  };

  getHospitalDoctorAppointments = () => {
    const { authUser } = this.props;
    try {
      services.getDoctorAppointmentsPatientOrHospital(
        "hospital",
        authUser.uid,
        (hospitalDoctorAppointments, error) => {
          if (!error) {
            this.setState({
              doctorAppointments: hospitalDoctorAppointments,
              loading: false,
            });
          }
        }
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  getHospitalServiceAppointments = () => {
    const { authUser } = this.props;

    try {
      services.getServiceAppointmentsPatientOrHospital(
        "hospital",
        authUser.uid,
        (hospitalServiceAppointments, error) => {
          if (!error) {
            this.setState({
              serviceAppointments: hospitalServiceAppointments,
            });
          }
        }
      );
    } catch (error) {
      console.log("Error -- ", error);
    }
  };

  render = () => {
    const {
      isOpenDoctor,
      isOpenService,
      loading,
      doctors,
      services,
      isOpenAddDoctorServices,
      isOpenTimeKeeping,
      selected,
      doctorAppointments,
      serviceAppointments,
    } = this.state;

    const { authUser } = this.props;
    const hospital = authUser.userDetails as Hospital;
    return (
      <>
        <Helmet>
          <title>MedBook - Hospital - Home</title>
        </Helmet>
        <MBContainer {...this.props} activePage="hospital-home">
          <div className="hospital-home-dashboard-card-container">
            <MBDashboardCard
              icon="doctor"
              title={_.filter(
                doctorAppointments,
                (appointment) =>
                  !appointment.isCancelled &&
                  isSameDate(new Date(), appointment.appointmentDate)
              ).length.toString()}
              message="Doctors Appointment Today"
            />
            <MBDashboardCard
              icon="ambulatory"
              title={_.filter(
                serviceAppointments,
                (appointment) =>
                  !appointment.isCancelled &&
                  isSameDate(new Date(), appointment.appointmentDate)
              ).length.toString()}
              message="Out-Patient Services Today"
            />
          </div>
          <div className="hospital-home-dashboard">
            <IonIcon className="hospital-home-icon" />
            {/* //todo: check if has doctors or services */}
            <IonLabel className="hospital-home-title mb-h1">
              {!_.isEmpty(services) || !_.isEmpty(doctors)
                ? "Good Day, Have a happy medical service!"
                : "You don't have Doctors and Services setup yet"}
            </IonLabel>
            <IonLabel className="mb-body">
              {!_.isEmpty(services) || !_.isEmpty(doctors)
                ? "Monitor the day to activity within your hospital or clinic"
                : "Let's start a good medical service by clicking the bottom below"}
            </IonLabel>
          </div>
          <div className="hospital-home-button-container">
            {!_.isEmpty(services) || !_.isEmpty(doctors) ? (
              <div>
                <IonButton
                  className="hospital-home-goto-button mb-body"
                  color={MBCOLORS.primary}
                  mode="ios"
                  fill="clear"
                  routerLink={routes.HOSPITAL_APPOINTMENTS}
                >
                  Go to Schedules
                </IonButton>
                <IonButton
                  className="hospital-home-goto-button mb-body"
                  color={MBCOLORS.primary}
                  mode="ios"
                  fill="clear"
                  routerLink={routes.HOSPITAL_DEPARTMENT}
                >
                  Go to My Department
                </IonButton>
              </div>
            ) : (
              <IonButton
                className="hospital-home-add-doctor-services mb-body"
                color={MBCOLORS.primary}
                mode="ios"
                disabled={loading}
                onClick={() => {
                  this.setState({ isOpenAddDoctorServices: true });
                }}
              >
                Add Doctors, Services or Time Keeping Now!
              </IonButton>
            )}
          </div>

          <IonLoading
            translucent={true}
            mode="ios"
            isOpen={loading}
            message={MSGS_COMMON.loading}
          />
        </MBContainer>
        <IonModal
          cssClass="hospital-home-add-dialog"
          isOpen={isOpenAddDoctorServices}
          onDidDismiss={() => {
            this.setState({ isOpenAddDoctorServices: false });
          }}
        >
          <div className="hospital-add-dialog-details-container">
            <IonLabel className="hospital-add-dialog-header mb-h1 dark-blue ion-text-start">
              Let's Get Started
            </IonLabel>
            <IonLabel className="hospital-add-dialog-description mb-body ion-text-start">
              Select any of the following that you wanted to add
            </IonLabel>
            <div
              className={`hospital-add-dialog-options-card-types-container ${
                isMobile() && "mobile"
              }`}
            >
              <div className="hospital-add-dialog-button-container">
                <div
                  className={`hospital-add-dialog-options-card-types-button ${
                    selected === USER_TYPES.doctors.id && "isSelected"
                  } ${isMobile() && "mobile"}`}
                  onClick={() => {
                    this.setState({ selected: USER_TYPES.doctors.id });
                  }}
                >
                  <div className="hospital-add-dialog-user-button-container">
                    <IonIcon className="hospital-add-dialog-icon patient" />
                    <IonLabel
                      className={`hospital-add-dialog-user-label ${
                        selected === USER_TYPES.doctors.id && "isSelected"
                      }`}
                    >
                      {USER_TYPES.doctors.name}
                    </IonLabel>
                  </div>
                </div>

                <div
                  className={`hospital-add-dialog-options-card-types-button ${
                    selected === USER_TYPES.services.id && "isSelected"
                  } ${isMobile() && "mobile"}`}
                  onClick={() => {
                    this.setState({ selected: USER_TYPES.services.id });
                  }}
                >
                  <div className="hospital-add-dialog-user-button-container">
                    <IonIcon className="hospital-add-dialog-icon ambulatory" />
                    <IonLabel
                      className={`hospital-add-dialog-user-label ${
                        selected === USER_TYPES.services.id && "isSelected"
                      }`}
                    >
                      {USER_TYPES.services.name}
                    </IonLabel>
                  </div>
                </div>

                <div
                  className={`hospital-add-dialog-options-card-types-button ${
                    selected === USER_TYPES.timeKeeping.id && "isSelected"
                  } ${isMobile() && "mobile"}`}
                  onClick={() => {
                    this.setState({ selected: USER_TYPES.timeKeeping.id });
                  }}
                >
                  <div className="hospital-add-dialog-user-button-container">
                    <IonIcon className="hospital-add-dialog-icon timeKeeping" />
                    <IonLabel
                      className={`hospital-add-dialog-user-label ${
                        selected === USER_TYPES.timeKeeping.id && "isSelected"
                      }`}
                    >
                      {USER_TYPES.timeKeeping.name}
                    </IonLabel>
                  </div>
                </div>
              </div>
            </div>

            <IonButton
              className="hospital-add-dialog-next-button"
              onClick={() => {
                if (selected === USER_TYPES.doctors.id) {
                  this.setState({ isOpenDoctor: true });
                } else if (selected === USER_TYPES.services.id) {
                  this.setState({ isOpenService: true });
                } else if (selected === USER_TYPES.timeKeeping.id) {
                  this.setState({ isOpenTimeKeeping: true });
                }
              }}
              mode="ios"
            >
              Next
            </IonButton>
          </div>
        </IonModal>
        {isOpenDoctor && (
          <DoctorHospitalAccountSetup
            isOpen={isOpenDoctor}
            doctors={doctors}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            hospitalDepartment={hospital.department}
            hospitalRepName={`${hospital.firstName} ${hospital.lastName}`}
            specialityMap={{} as any}
            onBack={() => {
              this.setState({
                isOpenDoctor: false,
              });
            }}
            onCancel={() => {}}
            {...this.props}
          />
        )}
        {isOpenService && (
          <ServiceAccountSetup
            isOpen={isOpenService}
            hospitalId={hospital.docId || ""}
            hospitalName={hospital.hospitalName}
            onBack={() => {
              this.setState({
                isOpenService: false,
              });
            }}
            onCancel={() => {}}
            {...this.props}
          />
        )}
        {isOpenTimeKeeping && (
          <TimeKeepingForm
            isOpen={isOpenTimeKeeping}
            hospitalId={hospital.docId || ""}
            onBack={() => {
              this.setState({
                isOpenTimeKeeping: false,
              });
            }}
            onCancel={() => {}}
            {...this.props}
          />
        )}
      </>
    );
  };
}

export default HospitalHome;
